import React from "react"
import Layout from "@components/layout"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { processImages } from "@utils/process-images"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import styled, { useTheme } from "styled-components"
import { useGradient } from "@hooks/use-gradient"
import { HighGradient } from "@components/common/gradient"
import Button from "@components/elements/button"
import GradientText from "@components/common/gradient-text"
import { TestimonialUser } from "@components/common/testimonial"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import media from "../styles/media"

const CaseText = ({ title, description, link }) => {
  const { color } = useTheme()
  return (
    <div>
      <Heading color={color.blue600} margin="0 0 1rem" as="h2">
        {title}
      </Heading>
      <Paragraph color={color.blue700} margin="0 0 .5rem">
        {description}
      </Paragraph>
      <Anchor color={color.blue400} link={link} fontSize={"1.2rem"} arrow>
        Read the case study
      </Anchor>
    </div>
  )
}

CaseText.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

const ReverseImage = styled(Image)`
  ${media.md`
    order: ${props => props.order};
   `}
`

const CaseStudy = ({ title, description, link, image, reverse }) => {
  return (
    <Grid
      gap="4rem"
      gapMd="2rem"
      columns="repeat(2, minmax(0,1fr))"
      columnsMd="1fr"
    >
      {reverse ? (
        <>
          <ReverseImage
            order={1}
            radius={1}
            file={image}
            style={{ aspectRatio: "1" }}
          />
          <CaseText title={title} description={description} link={link} />
        </>
      ) : (
        <>
          <CaseText title={title} description={description} link={link} />
          <Image radius={1} file={image} style={{ aspectRatio: "1" }} />
        </>
      )}
    </Grid>
  )
}

CaseStudy.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  reverse: PropTypes.bool,
}

const Reviews = () => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allDirectusFiles(
          filter: {
            directus_id: {
              in: [
                "99c96925-fe4e-4490-ad3d-aed66e16f10d"
                "a558d470-c109-47d5-9c18-9d217cad4663"
                "aaed27e6-f61f-47b3-8adb-4e9fc31ad3cd"
                "f5d75981-7b51-4b45-9c94-bde9810e22db"
                "9274414c-2f16-4637-9bc5-68d7a270ef94"
                "d82bf23d-74c1-46ff-8ac0-6587061ad803"
                "50babed4-eb7e-47d3-b84c-5a5ec7841ee5"
                "cf575bdf-b984-4193-b999-ab30ca9ac904"
                "e574677e-cd45-42e0-85ea-326b665f7956"
                "799d0804-f89c-457c-8899-d719efda0992"
                "59b55c70-0942-4a24-89d6-4020544029e2"
                "ec021e52-ff3a-4e33-94df-dbe421256a38"
              ]
            }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const { color, shadow } = useTheme()
  const { gradient } = useGradient(true)
  const images = processImages(data.images.edges)

  return (
    <Layout
      fullWidth
      title={`Klipfolio Reviews`}
      description={`At Klipfolio, we believe everyone can make successful data-driven decisions. They just need the right tools.`}
      marginless
    >
      <Div overflow="hidden" position="relative">
        <HighGradient src={gradient.cdn} loading="lazy" />
        <Grid
          margin="8rem auto 0"
          position="relative"
          container
          gap="4rem"
          gapMd="2rem"
          columns="minmax(0, 2fr) minmax(0, 3fr)"
          columnsMd="1fr"
        >
          <div>
            <Heading color={color.blue600} margin="0 0 1rem">
              Klipfolio Reviews
            </Heading>
            <Paragraph color={color.blue700} margin="0 0 0.5rem">
              At Klipfolio, we believe everyone can make successful data-driven
              decisions. They just need the right tools. Klipfolio is recognized
              as <b>most recommended</b> and as <b>Front Runner</b> (Software
              Advice) and <b>shortlisted</b> by Capterra in Data Visualization
              and Data Analysis.
            </Paragraph>
            <Paragraph margin="0 0 1.5rem">
              Thank you to all our customers for sharing their success story
              with Klipfolio.
            </Paragraph>
            <Button.Klips />
          </div>
          <ReverseImage
            radius={1}
            order={-1}
            borderStyle={{ boxShadow: shadow.default }}
            style={{ aspectRatio: 16 / 9 }}
            file={images["99c96925-fe4e-4490-ad3d-aed66e16f10d"]}
          />
        </Grid>
      </Div>
      <Div
        container
        margin="10rem auto"
        marginMd="8rem auto"
        marginSm="6rem auto"
      >
        <Heading
          center
          color={color.blue600}
          as="h2"
          fontSize="3rem"
          margin="0 0 2rem"
          marginMd="0 0 .5rem"
        >
          We love what we do, and
          <br />
          <GradientText variant={"klips"}>so do our customers</GradientText>
        </Heading>
        <Image
          height={150}
          objectFit="scale-down"
          file={images["a558d470-c109-47d5-9c18-9d217cad4663"]}
        />
      </Div>
      <Flex container gap="6rem" gapMd="4rem">
        <CaseStudy
          title="Planday track top Saas KPIs with custom visualization"
          description={`“We picked Klipfolio because the solution is very flexible. It enables us to work with many different data sources, while still being able to model and clean the data. Also, I think that the combination of price and functionally is excellent.” Ivan Zaykov, Finance Analyst at Planday`}
          link="/blog/metrics-and-modelling-dashboard-new"
          image={images["aaed27e6-f61f-47b3-8adb-4e9fc31ad3cd"]}
        />
        <CaseStudy
          title="Ascentis manages team performance"
          description={`“Our employees have become more ‘metrics-driven’ since the company started using Klipfolio. This includes our executive team who are using Klipfolio dashboards to present key company metrics during board meeting presentations.” Jose Sebastian Battig, EVP of SaaS Ops at Ascentis`}
          link="/blog/mitigate-performance-issues-with-klipfolio"
          image={images["f5d75981-7b51-4b45-9c94-bde9810e22db"]}
          reverse
        />
        <CaseStudy
          title="Making data-driven decisions faster with Klipfolio"
          description={`“Before Klipfolio, if I wanted to know a specific metric in any department including mine, I had to ask multiple people, eventually involve the IT team to run a report, and then clean and format the data myself so I could better understand it. It could take up to 3 days to obtain fairly basic information.” Rowan Stanek, Product Owner at Growth Institute`}
          link="/blog/mitigate-performance-issues-with-klipfolio"
          image={images["9274414c-2f16-4637-9bc5-68d7a270ef94"]}
        />
        <CaseStudy
          title="Truck dealer optimizes inventory and financial reporting"
          description={`“I can now see a history of my inventory over time; a mix of truck brands, inventory flow in and out of a certain location, the average cost per truck, and age of the vehicle. Klipfolio has helped me make better buying decisions now that I have access to these historical trends.” Shaun Boughen, President at Upper Canada Truck Sales`}
          link="/blog/auto-dealer-inventory-dashboard-reporting"
          image={images["d82bf23d-74c1-46ff-8ac0-6587061ad803"]}
          reverse
        />
      </Flex>
      <Grid
        margin="6rem auto"
        gap="2rem"
        container
        columns="repeat(3, minmax(0,1fr))"
        columnsMd="repeat(2, minmax(0,1fr))"
        columnsSm="1fr"
      >
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “Klipfolio stands out because of its power and flexibility, I like
            being able to build complex formulas. I also love the way it looks
            and handles from a design standpoint.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Erik van Dorp"
            title="Co-Owner, Cervinodata"
            image={images["50babed4-eb7e-47d3-b84c-5a5ec7841ee5"]}
          />
        </div>
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “When we discovered Klipfolio we were pleased with all the various
            integration options as well as the opportunity to visualize every
            data set we needed to track KPIs at every level of the
            organization.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Eric Granger"
            title="Director of Technology, GCS"
            image={images["cf575bdf-b984-4193-b999-ab30ca9ac904"]}
          />
        </div>
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “Klipfolio has outstanding data connection capabilities, beautifully
            designed visualizations, and excellent sharing options, all at a
            reasonable price point.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Marc Jellema"
            title="Founder, Omnia IT Consult"
            image={images["e574677e-cd45-42e0-85ea-326b665f7956"]}
          />
        </div>
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “Now that we are seeing our data in real-time, knowing the health of
            the business and how specific teams are performing on a daily basis
            has been a game changer.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Ben Sencenbaugh"
            title="President, The Reality Metrics"
            image={images["799d0804-f89c-457c-8899-d719efda0992"]}
          />
        </div>
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “We have several dashboards to show our daily, weekly, monthly, and
            yearly activity, and with real-time utilization rates of every
            product we carry.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Elliot Campbell"
            title="EVP & CCO, Trace Medical"
            image={images["59b55c70-0942-4a24-89d6-4020544029e2"]}
          />
        </div>
        <div>
          <Paragraph color={color.blue700} margin="0 0 1rem">
            “We’re showing our clients how they are now sending their audience
            to exactly where they want to consume and buy content.”
          </Paragraph>
          <TestimonialUser
            color={color.blue600}
            multiline
            name="Jason Wolfson"
            title="Senior VP Marketing, VuPulse"
            image={images["ec021e52-ff3a-4e33-94df-dbe421256a38"]}
          />
        </div>
      </Grid>
      <KlipsEOPCta />
    </Layout>
  )
}

export default Reviews
